$pink: darken(#f3a7c9, 10%);

html {
  font-family: 'Rubik', sans-serif;
  color: #666;
  font-size: 0.9em;
  line-height: 1.8;

  @media (max-width: 800px) {
    font-size: 0.75em;
    line-height: 1.6;
  }
}

a {
  color: $pink;
  text-decoration-color: rgba($pink, 0.3);

  &:hover {
    color: darken($pink, 10%);
  }
}

.masthead {
  padding: 2rem;

  a {
    color: #333;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.content {
  padding: 2rem;
  font-size: 1.6rem;
  max-width: 70ch;
  margin: 0 auto;
}

h1, h2, h3 {
  font-size: inherit;
}

hr {
  width: 5rem;
  text-align: left;
  background-color: #ccc;
  height: 1px;
  border: 0;
  margin: 3rem 0;
}

.post {
  margin-bottom: 2em;

  h1 {
    font-size: 3rem;
    color: $pink;
    margin-bottom: 0;
    line-height: 1.4;

    @media (max-width: 800px) {
      font-size: 2.6rem;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }

  h2 {
    color: $pink;
    font-size: 2rem;
    margin-bottom: -0.75em;
  }

  .date {
    font-size: 1.4rem;
    color: #ccc;
  }

  .excerpt {
    color: #999;
    font-size: 1.4rem;

     p {
      margin-bottom: 0;
     }
  }

  img {
    max-width: 100%;
  }
}

.note {
  margin: 5rem 0;
  background: $pink;
  padding: 2rem;
  color: #fff;
  border-radius: 0.25em;

  h3 {
    margin: 0;
    font-size: 1.2em;
  }

  p {
    margin: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.twitter-tweet {
  margin: 0 auto;
  padding: 2rem 0;
}
